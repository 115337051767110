<template>
  <el-dialog
    :modal="false"
    :close-on-click-modal="false"
    title="订单详情"
    top="5vh"
    :visible.sync="isshowDialog"
    width="1160px"
    @close="close"
  >
    <div v-loading="loading" class="checkOrder">
      <div
        style="
          width: 86%;
          margin-top: -70px;
          margin-left: 80px;
          cursor: pointer;
        "
        @click="handlerOldcoed"
      >
        <el-alert :closable="false" :title="titleTip" type="success">
          {{ form.cancle_str }}
        </el-alert>
      </div>

      <div class="orderStatus">{{ orderStatus2 }}</div>
      <el-form ref="form" :model="form" label-width="80px" :inline="true">
        <el-form-item label="购货方" prop="cust_name">
          <el-input v-model.trim="form.cust_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="boss">
          <el-input v-model.trim="form.boss" disabled></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="address" class="dz">
          <el-input
            v-model.trim="form.address"
            disabled
            style="width: 380px"
          ></el-input>
        </el-form-item>

        <el-form-item label="仓库" prop="depot_name">
          <el-input v-model.trim="form.depot_name" disabled></el-input>
        </el-form-item>

        <el-form-item label="送货人" prop="deliver_name">
          <el-input v-model.trim="form.deliver_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="业务员" prop="staff_name">
          <el-input v-model.trim="form.staff_name" disabled></el-input>
        </el-form-item>

        <el-form-item label="调拨单" prop="allot_code">
          <el-input v-model.trim="form.cen_code" disabled></el-input>
        </el-form-item>
        <el-form-item label="派单" prop="delivery_time">
          <el-input v-model.trim="form.delivery_time+''+form.from_user_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="到货" prop="arrive_time">
          <el-input v-model.trim="form.arrive_time+''+form.arrive_name" disabled></el-input>
        </el-form-item>
        <el-form-item label="派单人" prop="from_user_name">
          <el-input v-model.trim="form.from_user_name" disabled></el-input>
        </el-form-item>
        <el-form-item v-if="followuser.length != 0" label="随车人">
          <el-select
            v-model="form.follow_users"
            placeholder="随车人员"
            clearable
            multiple
            disabled
          >
            <el-option
              v-for="item_ in followuser"
              :key="item_.id"
              :label="item_.user_name"
              :value="item_.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="setFilter">
        <el-popover popper-class="custom-table-checkbox" trigger="hover">
          <el-checkbox-group v-model="checkList">
            <el-checkbox
              v-for="(item, index) in columns"
              :key="index"
              :label="item.label"
            ></el-checkbox>
          </el-checkbox-group>
          <el-button slot="reference">
            <vab-remix-icon icon="settings-line" />
          </el-button>
        </el-popover>
      </div>
      <!-- TODO 预订单查看加合计 -->
      <el-table
        ref="middleTable"
        stripe
        :data="middleTableData"
        show-summary
        :summary-method="summaryMethod"
      >
        <!-- 序号 -->
        <el-table-column prop="" align="center" label="" width="60px">
          <template #default="{ $index }">
            <span>{{ $index + 1 }}</span>
          </template>
        </el-table-column>
        <!-- 选择框 -->
        <el-table-column
          align="center"
          type="selection"
          width="45"
        ></el-table-column>
        <el-table-column
          v-for="(item, index) in finallyColumns"
          :key="index"
          align="center"
          :label="item.label"
          :sortable="item.sortable"
          :width="item.width"
        >
          <template v-if="item.label == '类型'" #default="{ row }">
            <el-select
              v-model="row.goods_type"
              disabled
              placeholder="请选择类型"
            >
              <el-option
                v-for="item__ in row.arr_goods_type"
                :key="item__.id"
                :label="item__.name"
                :value="item__.id"
              ></el-option>
            </el-select>
          </template>
          <template v-else-if="item.label == '商品名称'" #default="{ row }">
            {{ row[item.prop] }}
            <span v-if="row.is_abnormal == 2" style="color: #f60">
              {{ row.is_abnormal_type_text }}
            </span>
          </template>
          <template v-else-if="item.label == '金额'" #default="{ row }">
            <div v-if="row.goods_type == 6">
              <!-- {{ fushu(-Number(row[item.prop]), row) }} -->
              {{ -row[item.prop] }}
            </div>
            <div v-else>{{ row[item.prop] }}</div>
          </template>

          <template v-else-if="item.label == '到货数量'" #default="{ row }">
            <div v-if="form.bill_status == 3">
              <div v-if="Number(row.pre_quantity) === Number(row.quantity)">
                {{ row[item.prop] }}
              </div>
              <div v-else style="color: red">{{ row[item.prop] }}</div>
            </div>
            <div v-else>0</div>
          </template>

          <template v-else #default="{ row }">
            <span>{{ row[item.prop] }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 底部输入框 -->
      <el-form
        ref="form2"
        :model="form"
        label-width="120px"
        :inline="true"
        style="margin-top: 15px"
      >
        <!--        <div v-if="style == 1">-->
        <div style="width: 100%">
          <el-form-item label="备注" prop="remark">
            <div style="display: flex">
              <el-input
                v-model="form.remark"
                style="margin-right: 10px; width: 600px"
              ></el-input>
              <el-button type="primary" @click="handleSaveRemark">
                保存备注
              </el-button>
            </div>
          </el-form-item>
          <el-form-item label="下单时间" prop="remark" style="width: 100%">
            <el-date-picker
              v-model="form.create_at"
              disabled
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择下单时间"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="客户签名" prop="">
            <el-image
              v-if="form.sign_url"
              style="height: 26px"
              :preview-src-list="[form.sign_url]"
              :src="form.sign_url"
            ></el-image>
          </el-form-item>
        </div>
        <el-form-item label="订购总额" prop="total_amount">
          <el-input v-model.trim="form.total_amount" disabled></el-input>
        </el-form-item>
        <el-form-item label="使用预存款" prop="deposit_amount">
          <el-input
            v-model.trim="form.deposit_amount"
            disabled
            style="width: 110px"
          ></el-input>
          余额
          <el-input
            v-model.trim="form.cust_deposit_left"
            disabled
            style="width: 100px"
          ></el-input>
        </el-form-item>
        <el-form-item label="还货金额" prop="return_amount">
          <el-input v-model.trim="form.return_amount" disabled></el-input>
        </el-form-item>
        <el-form-item label="退货金额" prop="refund_goods_amount">
          <el-input v-model.trim="form.refund_goods_amount" disabled></el-input>
        </el-form-item>
        <el-form-item label="销售金额" prop="sale_amount">
          <el-input v-model.trim="form.sale_amount" disabled></el-input>
        </el-form-item>
        <el-form-item label="优惠" prop="discounted_amount">
          <el-input v-model.trim="form.discount_amount" disabled></el-input>
        </el-form-item>

        <el-form-item label="优惠后" prop="discounted_amount">
          <el-input v-model.trim="form.discounted_amount" disabled></el-input>
        </el-form-item>

        <el-form-item label="退货退款" prop="refund_amount">
          <el-input v-model.trim="form.refund_amount" disabled></el-input>
        </el-form-item>
        <el-form-item label="兑付现金" prop="thtk">
          <el-input v-model.trim="form.cash_amount"></el-input>
        </el-form-item>
        <el-form-item label="兑奖现金" prop="thtk">
          <el-input v-model.trim="form.prize_amount"></el-input>
        </el-form-item>
        <el-form-item
          v-if="form.support_amount != 0"
          label="费用抵扣"
          prop="thtk"
        >
          <el-input v-model.trim="form.support_amount"></el-input>
        </el-form-item>
        <el-form-item label="应收款" prop="receiv_money">
          <el-input v-model.trim="form.receiv_money" disabled></el-input>
        </el-form-item>
        <el-form-item label="结算方式" prop="account_type_text">
          <el-input v-model.trim="form.account_type_text" disabled></el-input>
        </el-form-item>
        <el-form-item label="销售收款:">
          <div style="display: flex">
            <el-input v-model.trim="form.staff_collect" disabled></el-input>
          </div>
        </el-form-item>
        <el-form-item label="派送收款:">
          <el-input v-model.trim="form.deliver_collect" disabled></el-input>
        </el-form-item>
        <el-form-item label="清欠收款" prop="refund_type">
          <el-input v-model="form.clear_collect" disabled></el-input>
        </el-form-item>
        <!--          <el-form-item label="退货方式" prop="refund_type">
            <el-input v-model="form.refund_type_text" disabled></el-input>
          </el-form-item>-->
        <!--        </div>
        <div v-else-if="style == 2">
          <el-form-item label="兑奖现金:" prop="prize_amount">
            <el-input v-model.trim="form.prize_amount" disabled></el-input>
          </el-form-item>
        </div>
        <div v-else-if="style == 3">
          <el-form-item label="兑付现金:" prop="cash_amount">
            <el-input v-model.trim="form.cash_amount" disabled></el-input>
          </el-form-item>
          <el-form-item v-if="form.sale_type == 4" label="备注" prop="remark">
            <div style="display: flex">
              <el-input
                v-model="form.remark"
                disabled
                style="margin-right: 10px; width: 530px"
              ></el-input>
            </div>
          </el-form-item>
        </div>-->
      </el-form>

      <!-- 小表格 -->
      <el-table
        v-if="style == 1"
        v-show="xTableData.length"
        ref="xTable"
        stripe
        :data="xTableData"
        show-summary
      >
        <el-table-column
          align="center"
          label="收款方式"
          width="auto"
          prop="pay_type_name"
        ></el-table-column>
        <el-table-column
          align="center"
          label="金额"
          width="auto"
          prop="amount"
        ></el-table-column>
        <el-table-column
          align="center"
          label="操作"
          width="auto"
          prop="operate_name"
        ></el-table-column>
        <el-table-column
          align="center"
          label="收款人"
          width="auto"
          prop="user_name"
        ></el-table-column>
        <el-table-column
          align="center"
          label="时间"
          width="auto"
          prop="create_at"
        ></el-table-column>
      </el-table>
      <!-- 底部按钮 -->
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button @click="handlePrint">打印</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </div>
    <!--    打印预览组件-->
    <ruilang-display-dialog
      ref="RuilangDisplayDialog"
      bill_type="XD"
      :data_id="id"
      :type="1"
    ></ruilang-display-dialog>
    <check-order ref="checkOrder"></check-order>
  </el-dialog>
</template>

<script>
  import _ from 'lodash'
  import { getAdvanceOrderEditTableList, saveRemark } from '@/api/advanceOrder'
  import RuilangDisplayDialog from '@/baseComponents/ruiLangDesign/ruilangDisplayDialog'
  import { postAction } from '@/api/Employee'
  export default {
    name: 'CheckOrder',
    components: { RuilangDisplayDialog },
    data() {
      return {
        followuser: [],
        isshowDialog: false,
        id: 0,
        style: 1,
        // 单号
        orderCode: '',
        // 下单时间
        orderTime: '',
        form: {},
        loading: true,
        title: '订单详情',
        orderStatus: '状态',
        // 合计
        totalUnitl: null,
        totalNumber: null,
        totalArrival: null,
        // 中间表格
        checkList: [
          '类型',
          '商品名称',
          '规格',
          '条码',
          '单位',
          '单价',
          '订购数量',
          '到货数量',
          '金额',
          '生产日期',
          '备注',
        ],
        orderStatus2: '',
        columns: [
          {
            order: 2,
            show: true,
            label: '类型',
            width: '120',
            prop: 'goods_type',
            sortable: false,
          },
          {
            order: 3,
            show: true,
            label: '商品名称',
            width: '230',
            prop: 'goods_name_print',
            sortable: false,
          },
          {
            order: 3.1,
            show: true,
            label: '规格',
            width: '130',
            prop: 'specs',
            sortable: false,
          },
          {
            order: 3.2,
            label: '条码',
            width: '110px',
            prop: 'unit_code',
            sortable: false,
          },
          {
            order: 4,
            show: true,
            label: '单位',
            width: '110', //'100',
            prop: 'unit_name',
            sortable: false,
          },
          {
            order: 5,
            show: true,
            label: '单价',
            width: '100',
            prop: 'goods_price',
            sortable: false,
          },
          {
            order: 6,
            show: true,
            label: '订购数量',
            width: '100',
            prop: 'pre_quantity',
            sortable: false,
          },
          {
            order: 7,
            show: true,
            label: '到货数量',
            width: '110',
            prop: 'quantity',
            sortable: false,
          },
          {
            order: 8,
            show: true,
            label: '金额',
            width: '100',
            prop: 'goods_money',
            sortable: false,
          },
          {
            order: 9,
            show: true,
            label: '生产日期',
            width: '110',
            prop: 'goods_production_date',
            sortable: false,
          },
          {
            order: 10,
            show: true,
            label: '备注',
            width: '260',
            prop: 'info',
            sortable: false,
          },
        ],
        middleTableData: [],
        xTableData: [],
        // 底部输入框
        // jsfsList: [
        //   {
        //     lable: '货到付款',
        //     value: '货到付款',
        //   },
        //   {
        //     lable: '账期结算',
        //     value: '账期结算',
        //   },
        // ],
        // form2: {
        //   dgze: '165',
        //   xsje: '230',
        //   syyck: '230',
        //   yhh: '230',
        //   hhje: '230',
        //   thtk: '230',
        //   thje: '230',
        //   ysk: '230',
        //   jsfs: '货到付款',
        //   xssk: '450',
        //   pssk: '0.00',
        // },
      }
    },
    computed: {
      titleTip() {
        return (
          '单号:' +
          this.orderCode +
          ' 下单： ' +this.form.create_name+
          this.form.sale_time +
          (this.form.old_id ? `原单: ${this.form.old_code}` : ' ') +
          ' ' +
          ' 修改人: ' +this.form.edit_name+
          this.form.edit_name +
          ' 修改时间: ' +
          this.form.edit_time
        )
      },
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        console.log(finallyArray)
        let a = _.sortBy(finallyArray, (item) => item.order)
        // if (this.orderStatus2 != '已到货') {
        //   console.log('不是已到货')
        //   a.splice(5, 0, {
        //     order: 7.7,
        //     label: '数量',
        //     show: true,
        //     width: '100',
        //     prop: 'quantity',
        //     sortable: false,
        //   })
        //   a.forEach((i, idx) => {
        //     if (i.order == 6 || i.order == 7) {
        //       a.splice(idx, 1)
        //     }
        //   })
        // }
        return a
      },
    },
    watch: {
      isshowDialog(val) {
        if (val) {
          this.getData()
        } else {
          this.loading = true
        }
      },
    },
    mounted() {
      this.handlerfollowuserlist()
    },
    methods: {
      handlerOldcoed() {
        if (this.form.old_id) {
          this.$refs['checkOrder'].showDialog()
          // this.$refs['checkOrder'].orderStatus = row.bill_status_text
          this.$refs['checkOrder'].id = this.form.old_id
        } else {
        }
      },
      async handlerfollowuserlist() {
        return await postAction('saleAdmin/order-pre/option', {}).then(
          (res) => {
            this.followuser = res.data.order_follow_users
          }
        )
      },
      fushu(num, row) {
        row.goods_price = num
        return num
      },
      async getData() {
        console.log('查看')

        let { data, msg, code } = await getAdvanceOrderEditTableList({
          id: this.id,
        })
        console.log(data)
        if (code == 200) {
          this.data = data
          this.orderStatus = data.order_info.check_status_text
          if (!this.orderStatus2) {
            if (data.order_info.check_status == 0) {
              this.orderStatus = data.order_info.bill_status_text
            } else {
              this.orderStatus = data.order_info.check_status_text
            }
          }
          this.form = data.order_info
          this.form.cen_code =
            this.form.allot_code + this.form.cancel_allot_code
          if (this.form.follow_users != '') {
            this.form.follow_users = data.order_info.follow_users.split(',')
          } else {
            this.form.follow_users = []
          }
          this.orderCode = this.form.bill_code
          this.form.boss = this.form.boss + ' ' + this.form.mobile
          this.totalUnitl = data.order_info.goods_money_total // 金额
          this.totalNumber = data.order_info.goods_total_pre // 订购数量
          this.totalArrival = data.order_info.goods_total // 到货数量
          // 表格
          this.middleTableData = data.order_detail_info
          // 处理车牌号
          let carCode = this.data.arr_deliver.filter(
            (item) => this.form.deliver_id == item.id
          )[0]
          console.log(carCode)
          if (!carCode) {
            carCode = ''
          } else {
            carCode = carCode.depot_carno
          }
          this.form.deliver_name = this.form.deliver_name + ' ' + carCode
          // bottom table
          this.xTableData = data.arr_collect

          // style样式选择
          if (
            this.form.sale_type == '1' ||
            this.form.sale_type == '2' ||
            this.form.sale_type == '3'
          ) {
            console.log('1样式')
            this.style = 1
          } else if (this.form.sale_type == '5' || this.form.sale_type == '6') {
            console.log('兑奖样式')
            this.style = 2
          } else if (this.form.sale_type == '4') {
            console.log('兑付样式')
            this.style = 3
          } else {
            this.style = 1
          }
        }
        this.loading = false
      },
      showDialog() {
        this.isshowDialog = true
      },
      summaryMethod() {
        console.log('合计')
        return [
          '合计',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          this.totalNumber,
          this.form.bill_status != 3 ? 0 : this.totalArrival,
          this.form.goods_money_total,
        ]
        // if (this.form.buill_status == 3) {
        //   // 到货
        //   return [
        //     '合计',
        //     '',
        //     '',
        //     '',
        //     '',
        //     '',
        //     '',
        //     // this.totalNumber,
        //     this.totalArrival,
        //     this.form.total_amount,
        //   ]
        // } else {
        //   // pre
        //   return [
        //     '合计',
        //     '',
        //     '',
        //     '',
        //     '',
        //     '',
        //     '',
        //     // this.totalNumber,
        //     this.totalArrival,
        //     this.form.total_amount,
        //   ]
        // }
      },
      close() {
        this.isshowDialog = false
      },
      handleSaveRemark() {
        saveRemark({ id: this.id, remark: this.form.remark }).then((res) => {
          this.$message.success('保存成功')
        })
      },
      handlePrint() {
        this.$refs['RuilangDisplayDialog'].dialogFormVisible = true
      },
    },
  }
</script>

<style lang="scss" scoped>
  .setFilter {
    top: 41px;
    left: 7px;
    display: none;
  }
</style>
